import React, { useState, useEffect } from "react";
import classes from "./forgot.module.css";
import { ToastContainer } from "react-toastify";
import { passwordCodeVerification, sendPasswordResetCode } from "../../services/authService";
import { useNavigate } from "react-router-dom";

const VerifyCode = () => {
    const navigate = useNavigate();
    const [remainingTime, setRemainingTime] = useState(120);
    const [code, setCode] = useState("");
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const email = new URLSearchParams(window.location.search).get("email");

    useEffect(() => {
        if (remainingTime > 0) {
            const timer = setInterval(() => {
                setRemainingTime((prevTime) => prevTime - 1);
            }, 1000);

            return () => {
                clearInterval(timer);
            };
        }
    }, [remainingTime]);

    const formatTime = () => {
        const minutes = Math.floor(remainingTime / 60)
            .toString()
            .padStart(2, "0");
        const seconds = (remainingTime % 60).toString().padStart(2, "0");
        return `${minutes}:${seconds}`;
    };

    const handleResendClick = async () => {
        if (remainingTime === 0) {
            try {
                await sendPasswordResetCode({ email: email });
            } catch (error) {
                if (error.response && error.response.status === 422) {
                    setErrorMessage("İstifadəçi emailini düzgün deyil!");
                } else {
                    setErrorMessage("Xəta baş verdi!");
                }
            }
            setRemainingTime(120);
        }
    };

    const handleVerifyClick = async () => {
        setLoading(true);
        try {
            await passwordCodeVerification({ email, code });
            navigate("/reset-password?email=" + email + "&code=" + code);
        } catch (error) {
            if (error.response && error.response.status === 422) {
                setErrorMessage("Kod 6 simvoldan ibarət olmalıdır!");
            } else if (error.response && error.response.status === 400) {
                setErrorMessage("Kod yanlışdır!");
            } else {
                setErrorMessage("Xəta baş verdi!");
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <div className={classes.forgot_main}>
                <div className={classes.forgot_content}>
                    <h3> Şifrəni unutmusuz?</h3>
                    <p className={classes.contentText}>Emailinizə göndərilən kodu daxil edin</p>
                    <div className={classes.timer}>{remainingTime > 0 ? `${formatTime()}` : ""}</div>
                    {remainingTime === 0 && (
                        <p className={classes.request_btn} onClick={handleResendClick}>
                            Yenidən göndər
                        </p>
                    )}
                    <input type="text" onChange={(e) => setCode(e.target.value)} />
                    {errorMessage && <p className='dangerText'>{errorMessage}</p>}
                    <div className={classes.success_btn}>
                        <button className="btn btn__primary" onClick={handleVerifyClick} disabled={loading}>
                            {loading ? "Təsdiqlənir..." : "Təsdiqlə"}
                        </button>
                    </div>
                </div>
            </div>
            <ToastContainer position="bottom-right" />
        </>
    );
};

export default VerifyCode;
