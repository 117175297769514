import React, { useCallback, useEffect, useState } from 'react'
import classes from "./navbar.module.css"
import { useContext } from "react";
import { MenuContext } from "../../context/MenuContext";
import { Link, useLocation, useNavigate, useSearchParams, useParams } from "react-router-dom";
import logo from "../../assets/images/logo.png"
import { Car, Equipment, Moto } from '../../assets/icons/Icons';
import { FaHeart, FaUser } from "react-icons/fa"
import { FaShoppingCart } from "react-icons/fa"
import { FiSearch } from "react-icons/fi"
import { TiLocation } from "react-icons/ti"
import { Bar } from '../../assets/icons/Icons';
import mobilLeft from "../../assets/images/top.png"
import MobilNavbar from './mobilnav/mobilnavbar';
import CountUp from 'react-countup';
// import { useTranslation } from "react-i18next";
import { ModalNav } from './modalNavbar/modalNav';
import { ModalNavSalon } from './modalNavbarSalon/modalNavSalon';
import { useAutoComplete, useProductCount } from '../../hooks/query/products';
import useDebounce from '../../hooks/useDebounce';
import { AuthContext } from '../../context/AuthContext';
import BannerSlider from '../../components/bannerslider';
import Card from '../../components/ui/card/card';

import axios from "axios";
// import { baseURL } from "../../../helpers/api/request";
import { baseURL } from "../../helpers/api/request";

const Navbar = () => {
  // const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const { active, setActive, showBasket } = useContext(MenuContext);

  const { isAuthenticated, setUser, user, setIsAuthenticated, wishLists, basketListsCount } = useContext(AuthContext)

  const { data } = useProductCount()

  const [showLang, setShowLang] = useState(false)
  const [visibleRegister, setVisibleRegister] = useState(false)
  const [modalSearch, setModalSearch] = useState(false)
  const [openSearch, setOpenSearch] = useState(false)
  const [isModalOpenNav, setIsModalOpenNav] = useState(false);
  const [isModalOpenNavSalon, setIsModalOpenNavSalon] = useState(false);
  const [query, setQuery] = useState("");
  const debouncedSearchTerm = useDebounce(query, 200);
  const { data: autoCompleteData, isFetching: autoCompelteIsFetching, refetch } = useAutoComplete(debouncedSearchTerm);
  const [searchParams] = useSearchParams();
  const [queryItem, setQueryItem] = useState('')

  const [isVisible, setIsVisible] = useState(true);
  const [prevScrollY, setPrevScrollY] = useState(100);

  const handleScroll = () => {
    const currentScrollY = window.scrollY;

    if(currentScrollY > prevScrollY && currentScrollY > 200) {
      // Aşağı kaydırma ve 200'den büyükse
      setIsVisible(false);
    } else if(currentScrollY < prevScrollY && currentScrollY <= 10) {
      // Yukarı kaydırma ve 200'den küçükse
      setIsVisible(true);
    }

    setPrevScrollY(currentScrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // const [apiResponse, setApiResponse] = useState(null);
  // const [refreshCount, setRefreshCount] = useState(() => {
  //   return parseInt(localStorage.getItem('refreshCount')) || 0;
  // });

  // const [ipAddress, setIpAddress] = useState(() => {
  //   return localStorage.getItem('ipAddress') || '';
  // });

  // // const navigate = useNavigate();
  // const { page } = useParams();

  // useEffect(() => {
  //   fetch('https://ipinfo.io/json')
  //     .then(response => response.json())
  //     .then(data => {
  //       setIpAddress(data.ip);
  //       localStorage.setItem('ipAddress', data.ip);
  //     });
  // }, []);

  // useEffect(() => {
  //   setRefreshCount(prevCount => {
  //     const newCount = prevCount + 1;
  //     localStorage.setItem('refreshCount', newCount);
  //     return newCount;
  //   });
  // }, [page]);


  // useEffect(() => {
  //   handleClick();
  // }, []);

  // // useEffect(() => {
  // //   handleClick();
  // // }, [refreshCount, ipAddress]);

  // const handleClick = async () => {
  //   try {
  //     const response = await axios.post(`${baseURL}/traffic`, {
  //       data: {
  //         count: `${refreshCount}`,
  //       },
  //       user_ip: `${ipAddress}`,
  //     });

  //     // console.log('API Response:', response.data);
  //     setApiResponse(response.data);
  //   } catch(error) {
  //     console.error('Error:', error.response?.data);
  //   }
  // };

  // const apiCountItwem = apiResponse?.data?.count
  // { apiCountItwem }



  const [apiResponse, setApiResponse] = useState(null);
  const [refreshCount, setRefreshCount] = useState(0);
  const [ipAddress, setIpAddress] = useState('');

  const { page } = useParams();

  useEffect(() => {
    fetch('https://ipinfo.io/json')
      .then(response => response.json())
      .then(data => {
        setIpAddress(data.ip);
      });
  }, []);

  const handleClick = async () => {
    try {
      const response = await axios.post(`${baseURL}/traffic`, {
        data: {
          count: `${refreshCount}`,
        },
        user_ip: `${ipAddress}`,
      });

      setApiResponse(response.data);
      setRefreshCount(prevCount => prevCount + 1);
    } catch(error) {
      console.error('Error:', error.response?.data);
    }
  };

  useEffect(() => {
    handleClick();
  }, [page, ipAddress]);

  const apiCountItem = apiResponse?.data?.count;

  // console.log(apiCountItem);

  // const handleClick = () => {
  //   onSubmitCount();
  // }
  // const onSubmitCount = async (data) => {
  //   try {
  //     const response = await axios.post(`${baseURL}/traffic`, {
  //       data: {
  //         count: `${refreshCount}`,
  //       },
  //       user_ip: `${ipAddress}`,
  //       headers: { "Content-Type": "multipart/form-data" },
  //     });

  //   } catch(error) {
  //     console.error('Error:', error.response.data);
  //   }

  // }

  // const CarPagePath = '/car';
  // const EquipmentPagePath = '/equipment';
  // const MotoPagePath = '/moto';


  const handleLogout = () => {
    setIsAuthenticated(false)
    setUser(null)
    localStorage.removeItem('token');
    localStorage.removeItem('userData');
    // setVisibleRegister(false);
  }

  // const [lang,setLang]=useState(localStorage.getItem('lang'))
  // const langs = ["en", "ru"]


  // const mobilHandle = () => {
  //   setModalSearch(true)
  //  
  // }

  useEffect(() => {
    const handleBodyClick = (e) => {
      const clickedElement = e.target;
      if(clickedElement.classList.contains('showLoginItem')) {
      } else {
        setVisibleRegister(false);
      }
    };

    document.addEventListener('click', handleBodyClick);

    return () => {
      document.removeEventListener('click', handleBodyClick);
    };
  }, []);


  useEffect(() => {
    // setVisibleRegister(false);
    if(debouncedSearchTerm) refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  const handleChange = (e) => {
    setQueryItem(() => e.target.value)

    if(e.target.value.length > 0) {
      setOpenSearch(true)
      setModalSearch(true)
      setQuery(e.target.value)

    } else {
      setOpenSearch(false)
      setModalSearch(false)
    }
  }

  const handleOverlay = () => {
    setModalSearch(false)
    setOpenSearch(false)
  }

  const formatNumber = useCallback((value) => {
    const digits = value.toString().split("");
    const formattedDigits = digits.map((digit, index) => (
      `<span style="background:linear-gradient(180.77deg, #434242 24.2%, #8A8A8A 151.61%); ">${digit}</span>`
    ));

    return formattedDigits;
  }, []);


  //   const handleLang=(lang)=>{
  //    i18n.changeLanguage(lang);
  //    localStorage.setItem('lang', JSON.stringify(lang));
  //    setLang(lang)
  //    setShowLang(!showLang)

  // }
  // const filterLang=langs.filter((item)=>item!==lang)



  const openModal = () => {
    setIsModalOpenNav(true);
  };
  const openModalSalon = () => {
    setIsModalOpenNavSalon(true);
  };

  const handleSearch = (e) => {
    e.preventDefault()
    searchParams.set("search", debouncedSearchTerm);

    if(location.pathname === "/products") {
      navigate(`?${searchParams.toString()}`);
    } else {
      navigate(`/product?${searchParams.toString()}`);
    }

    setModalSearch(false)
    setOpenSearch(false)
    setQueryItem('');
  }

  const closeAutoComplete = (query) => {
    setModalSearch(false)
    setOpenSearch(false)
    setQueryItem(query)

  }


  useEffect(() => {
    const handleKeyUp = (event) => {
      if(event.key === "Enter") {
        setModalSearch(false)
        setOpenSearch(false)
      }
    };

    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);



  const img_url = "https://s3.eu-central-1.amazonaws.com/uploads.avtopro.general/storage/"
  const img_small_url = "https://s3.eu-central-1.amazonaws.com/uploads.avtopro.general/"


  const overlaySearchClass = modalSearch
    ? `${classes.formSearch} ${classes.activeSearchForm} `
    : classes.formSearch;


  return (
    <>

      <nav>
        <div onClick={handleOverlay} className={modalSearch ? "overlay-search active" : "overlay-search"} ></div>
        <div onClick={() => setActive(!active)} className={active ? "overlay-search overlay-search-mobil  active" : "overlay-search"} ></div>

        {/* <div className={`${classes.nav__top}`}>

          <Link >{Car} {t("car")}</Link>
          <Link >{Equipment} Ağır texnika</Link>
          <Link >{Moto}  {t("moto")}  </Link>

          <Link to="/car" className={`${classes.car_icon}`}>Avtomobil</Link>
          <Link to="/equipment" className={`${classes.truck_icon}`}>Ağır Texnika</Link>
          <Link to="/moto" className={`${classes.moto_icon}`}>Motosiklet</Link>

          <div className={`${classes.nav__top_icons}`}>
            <Link to="/tezlikle" className={`${classes.car_icon}`}>Avtomobil</Link>
            <Link to="/tezlikle" className={`${classes.truck_icon}`}>Ağır Texnika</Link>
            <Link to="/tezlikle" className={`${classes.moto_icon}`}>Motosiklet</Link>
          </div>
          <div className={`${classes.site_stat}`}>
            {apiCountItem}
          </div>

        </div> */}

        {/* banner part */}
        {isVisible &&
          <div className={classes.mobilShowBanner}>
            <BannerSlider />
          </div>}
        {/* banner part */}

        <section className={`${classes.nav__bottom} container sect_relative`}>
          <div className={`${classes.flex} ${classes.mobilFlex} `}>
            <Link className={classes.mobilBar} onClick={() => setActive(!active)}>{Bar}</Link>
            <Link to="/" className={classes.mobilLeft}><img src={mobilLeft} alt='Logo' /> </Link>
          </div>

          <Link to="/" className={classes.logo}> <img src={logo} alt="Logo" /></Link>

          <div className={`${classes.site_stat}`}>
            {apiCountItem}
          </div>
          <div className={classes.nav_items}>
            {/* <button className='btn btn__primary btn__nav' >Elan yerləşdir</button> */}
            <button className='btn btn__primary btn__nav' onClick={openModalSalon}>Avtosalon aç</button>
            <button className='btn btn__primary btn__nav' onClick={openModal}>Mağaza aç</button>
          </div>
          {/* <div className={classes.countUp}>
            <CountUp
              start={0}
              end={data?.data?.count}
              separator=""
              duration={3}
              formattingFn={formatNumber}
            />
          </div> */}
          <div className={`${classes.flex}  ${classes.search_item} `}>
            <form onSubmit={handleSearch} className={`${overlaySearchClass} `}>

              <input type="text" placeholder='Search...' value={queryItem} onChange={handleChange} onClick={() => setModalSearch(true)} />
              {autoCompelteIsFetching ?
                <button><i className="fas fa-circle-notch fa-spin"></i></button> :
                <button type="submit" ><FiSearch color='#68A834' fontSize="2rem" /></button>
              }

              <>
                {openSearch && <ul className={classes.openSearchMenu} style={{ height: autoCompleteData?.data?.products?.length >= 0 ? "50rem" : "0" }} >
                  {
                    autoCompleteData?.data?.products?.map((item, i) => (
                      <li key={i}>
                        <Link to={`/product?search=${item}`} onClick={() => closeAutoComplete(item)}>{item}</Link>
                      </li>
                    ))
                  }
                  <div className={classes.searchContainer} >
                    {
                      autoCompleteData?.data?.categories?.map((category, i) => (
                        <article key={category?.id} className={`${classes.boxSearch} box_item`} >
                          <Link to={`/products?category_id=${category?.id}`} onClick={() => closeAutoComplete()}> <img src={img_url + category?.icon} alt={category?.category_name} /></Link>
                          <p>{category?.category_name}</p>
                        </article>
                      ))
                    }
                  </div>
                  <div className={classes.searchContainer} >
                    {
                      autoCompleteData?.data?.products_items?.map((card) => (
                        <Card key={card.id} card={card} imgHeight={8} />
                      ))
                    }
                  </div>
                  <div className='more'><Link to={`/product?search=${query}`} onClick={() => closeAutoComplete()} >Hamısına bax</Link></div>
                </ul>}

              </>

            </form>
            <Link to="/cart/cartlist" className={`  ${classes.mobilShow}`}>
              <FaShoppingCart color={basketListsCount?.length > 0 ? "#68A834" : '#434242'} fontSize="2rem" />
              <sup className={classes.sup}>{basketListsCount?.length > 0 ? basketListsCount.length : null}</sup>
            </Link>
          </div>
          <div className={`${classes.flex}  ${classes.mobilHidden}`}>
            <div className={classes.langs}>
              <Link className={classes.lang} onClick={() => setShowLang(!showLang)}> AZE <i className="fas fa-angle-down "></i></Link>
              {/* {
                    showLang && 
                    <div className={classes.dropdown_lang}>
                       {
                        langs.map((item,i)=>(
                          <Link key={i} onClick={()=>handleLang(item)}>{item}</Link>
                        ))
                       }
                  </div>
                  } */}
            </div>
            <Link to="/gomap"><TiLocation color='#639A46' fontSize="2.2rem" /></Link>

          </div>
          <div className={`${classes.flex}  ${classes.mobilHidden}`}>
            <Link to="/cart/cartlist" className='basket_btn btn_relative' ><FaShoppingCart color={basketListsCount?.length > 0 ? "#68A834" : '#434242'} fontSize="1.8rem" />
              <sup className={classes.sup}>{basketListsCount?.length > 0 ? basketListsCount.length : null}</sup>

              {basketListsCount.length > 0 ? <div className={`${classes.small_basket} ${showBasket ? "" : 'sm_basket'} `}>
                {/* <span>{successTick}<h5>Səbətə əlavə edildi!</h5></span> */}
                <div className={classes.product_detail}>
                  <div className={classes.product_img}>
                    <img src={img_small_url + basketListsCount[basketListsCount.length - 1]?.product?.cover} alt={basketListsCount[basketListsCount.length - 1]?.product?.product_name} />
                  </div>
                  <div className={classes.product_info}>
                    <p>{basketListsCount[basketListsCount.length - 1]?.product?.product_name}</p>
                    <h3>{basketListsCount[basketListsCount.length - 1]?.product?.price} AZN</h3>
                  </div>
                </div>
                <div>
                  <button className="btn LightBtn">Səbətə get</button>
                </div>
              </div> : null}

            </Link>

            <div className={classes.visibleRegister}>
              <Link className={`${classes.dropdown_setting} showLoginItem`} onClick={() => setVisibleRegister(!visibleRegister)}><FaUser color={isAuthenticated ? "#68A834" : '#434242'} fontSize="1.7rem" /></Link>
              {
                visibleRegister &&
                <div className={classes.dropdown_lang}>
                  <h3 className={classes.registerLink}>
                    <Link to="/cart/cartlist" onClick={() => setVisibleRegister(!visibleRegister)}><FaUser color='#434242' fontSize="1.6rem" /></Link>
                    {isAuthenticated ? user.name : "Şəxsi kabinetim"}
                  </h3>
                  <div className={classes.btns__login}>
                    {isAuthenticated && <Link to="/" onClick={handleLogout} className='btn btn__primary'>Çıxış</Link>}
                    {!isAuthenticated && <Link to="/register" onClick={() => setVisibleRegister(!visibleRegister)} className='btn btn__primary'> Daxil ol </Link>}
                  </div>
                </div>
              }
            </div>

            <Link to="/cart/wishlist"> <FaHeart color={wishLists?.length > 0 ? "#68A834" : '#434242'} fontSize="1.8rem" /> <sup className={classes.sup}>{wishLists?.length > 0 ? wishLists.length : null}</sup></Link>

          </div>

        </section>


        <MobilNavbar />

        <>
          <ModalNav isModalOpenNav={isModalOpenNav} setIsModalOpenNav={setIsModalOpenNav} />
          <ModalNavSalon isModalOpenNavSalon={isModalOpenNavSalon} setIsModalOpenNavSalon={setIsModalOpenNavSalon} />
        </>
      </nav >
    </>
  )
}

export default Navbar