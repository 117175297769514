import { useForm } from "react-hook-form";
import classes from "./forgotPassword.module.css";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./yup";
import { sendPasswordResetCode } from "../../services/authService";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";

const ForgotPassword = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState(null);
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        if (errorMessage) {
            setTimeout(() => {
                setErrorMessage("");
            }, 3000);
        }
    }, [errorMessage]);
    const onSubmit = async (data) => {
        setLoading(true);
        try {
            const response = await sendPasswordResetCode(data);
            console.log(response);
            reset();
            toast.success(response.message);
            navigate("/verify-code?email=" + data.email);
        } catch (error) {
            if (error.response && error.response.status === 422) {
                setErrorMessage("İstifadəçi emailini düzgün daxil edin!");
            } else {
                setErrorMessage("Xəta baş verdi!");
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <div className={`${classes.main} container`}>
                <div className={classes.content}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <h3>Şifrəni unutmusuz?</h3>
                        <p>
                            Daxil edilən elekton hesaba kod göndəriləcək. Elektron hesabınız qeydiyyatdan keçdiyiniz
                            hesab ilə eyni olmalıdır.
                        </p>
                        <div className={classes.recovery_input}>
                            <label htmlFor="recovery_email">Email</label>
                            <input type="text" id="recovery_email" {...register("email")} />
                            {errors.email?.message && <p className="dangerText">{errors.email?.message}</p>}
                            {errorMessage && <p className="dangerText">{errorMessage}</p>}
                        </div>

                        <div className={classes.send_btn}>
                            <button className="btn btn__primary" disabled={loading}>
                                {loading ? "Göndərilir..." : "Göndər"}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <ToastContainer position="bottom-right" />
        </>
    );
};

export default ForgotPassword;
