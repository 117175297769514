import React, { useEffect, useState } from "react";
import classes from "./setPassword.module.css";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { passwordCodeVerification, resetPassword } from "../../services/authService";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./yup";

const SetPassword = () => {
    const navigate = useNavigate();
    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const code = new URLSearchParams(window.location.search).get("code");
    const email = new URLSearchParams(window.location.search).get("email");

    useEffect(() => {
        const checkCode = async () => {
            try {
                await passwordCodeVerification({ email, code });
            } catch (error) {
                toast.error("Kod yanlışdır!");
                navigate("/forgot-password");
            }
        };
        checkCode();
    }, [code, email, navigate]);

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (data) => {
        setLoading(true);
        try {
            await resetPassword({ email, code, ...data });
            reset();
            toast.success("Şifrə uğurla dəyişdirildi!");
            navigate("/register");
        } catch (error) {
            if (error.response && error.response.status === 422) {
                setErrorMessage("İstiadəçi emailini səhvdir!");
            } else if (error.response && error.response.status === 400) {
                setErrorMessage("Kod yanlışdır!");
            } else {
                setErrorMessage("Xəta baş verdi!");
            }
        } finally {
            setLoading(false);
        }
    };

    const togglePasswordVisibility1 = () => {
        setShowPassword1(!showPassword1);
    };

    const togglePasswordVisibility2 = () => {
        setShowPassword2(!showPassword2);
    };

    return (
        <div className={classes.main}>
            <div className={classes.content}>
                <h3>Şifrə təyin edin</h3>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={classes.input_form}>
                        <div className={classes.inputs}>
                            <label htmlFor="password">Yeni şifrə</label>
                            <input type={showPassword1 ? "text" : "password"} id="password" {...register("password")} />
                            <span className={classes.eye} onClick={togglePasswordVisibility1}>
                                {showPassword1 ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                            </span>
                            <p className="dangerText">{errors.password?.message}</p>
                        </div>
                        <div className={classes.inputs}>
                            <label htmlFor="password_confirmation">Yeni şifrənin təkrarı</label>
                            <input
                                type={showPassword2 ? "text" : "password"}
                                id="password_confirmation"
                                {...register("password_confirmation")}
                            />
                            <span className={classes.eye} onClick={togglePasswordVisibility2}>
                                {showPassword2 ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                            </span>
                            <p className="dangerText">{errors.password_confirmation?.message}</p>
                            {errorMessage && <p className="dangerText">{errorMessage}</p>}
                        </div>
                        <div className={classes.submit_btn}>
                            <button className="btn btn__primary" disabled={loading}>
                                {loading ? "Gözləyin..." : "Təsdiqlə"}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default SetPassword;
