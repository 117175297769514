import React, { useContext, useState } from "react";
import "./register.css";
// import "../carts/privateinfo/privateinfo.css"
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { login } from "../../services/authService";
import { AuthContext } from "../../context/AuthContext";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

const Login = () => {
    const { setUser, setIsAuthenticated } = useContext(AuthContext);
    const navigate = useNavigate();
    const [errorMessages, setErrorMessages] = useState("");
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [incorrectPassword, setIncorrectPassword] = useState(false);

    const {
        register,
        handleSubmit,
        reset,
        // formState: {},
    } = useForm({});

    const onSubmit = async (data) => {
        setErrorMessages("");
        setLoading(true);
        setIncorrectPassword(false);

        try {
            const response = await login(data);
            localStorage.setItem("token", response?.data?.access_token);
            const myObjStr = JSON.stringify(response?.data?.user);
            localStorage.setItem("userData", myObjStr);
            setUser(response?.data?.user);
            setIsAuthenticated(true);
            navigate("/");
            reset();
        } catch(error) {
            if(error?.response?.status === 422) {
                setErrorMessages(error?.response?.data?.errors ?? {});
            } else if(error?.response?.status === 401) {
                setIncorrectPassword(true);
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <h3>Hesaba daxil ol</h3>
                <div className="privateForm">
                    <span>E-mail</span>
                    <input type="text" placeholder="E-mail" {...register("email")} />
                    <p className="errorMessage">{errorMessages?.email}</p>
                </div>
                <div className="privateForm">
                    <span>Şifrə*</span>
                    <input
                        type={!showPassword ? "password" : "text"}
                        placeholder="********"
                        {...register("password")}
                    />
                    {!showPassword ? (
                        <AiFillEyeInvisible style={eye} onClick={() => setShowPassword(!showPassword)} />
                    ) : (
                        <AiFillEye style={eye} onClick={() => setShowPassword(!showPassword)} />
                    )}
                    {/* <p className='errorMessage'>{errorMessages?.password}</p> */}
                    {incorrectPassword ? <p className="errorMessage"> Giriş məlumatları səhvdir </p> : ""}
                </div>
                <button type="submit" className="btn btn__primary ">
                    {loading ? (
                        <>
                            Göndərilir &nbsp; <i className="fas fa-circle-notch fa-spin"></i>
                        </>
                    ) : (
                        "Daxil ol"
                    )}
                </button>
            </form>
        </>
    );
};

export default Login;

const eye = {
    position: "absolute",
    right: "1.5rem",
    top: "55%",
    cursor: "pointer",
    color: "#8E8E8E",
};
